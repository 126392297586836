@use "../variables" as v;

.play-body {
  // padding: 0.25em;
  // border: 1px solid black;
  white-space: pre;
  // padding-left: 1em;
  // justify-content: space-between;
  // border-bottom: 1px solid black;
  border: 1px solid black;
  padding: 0.25em;
  border-radius: 10px;
  overflow: hidden;
  // background-color: black;
}

.play-body-playing {
  // background-color: v.$secondary;
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  // height: 100vh;
}

body {
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  // height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
