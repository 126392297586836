$sample-tray-height: 100vh;
// $primary: #161616;
$primary: #9e9e9e;
// $primary: #9e9e9e;
$primaryDark: #1f1f1f;
$secondary: #fff200;
$secondaryHover: #b38900;
$primaryInactive: #4c4c4c;
$transparent: #0000;
$stroke: black;
$border: 3px solid black;
$borderRadius: 10px;
$textDark: black;
$text: white;
