@use "variables" as v;

.body-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

$dot-size: 1px;
$dot-space: 22px;
$bg-color: v.$primary;
$dot-color: black;

$bg-color2: v.$primary;
$dot-color2: v.$secondary;

.dot-fill {
  background: linear-gradient(
        90deg,
        $bg-color ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;
}

.dot-fill-2 {
  background: linear-gradient(
        90deg,
        $bg-color2 ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color2 ($dot-space - $dot-size), transparent 1%) center,
    $dot-color2;
  background-size: $dot-space $dot-space;
}
//

.lines {
  background: linear-gradient(to right, rgb(0, 255, 55) 1px, transparent 1px);
  background-size: 20px;
}

.grid-fill {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC")
    repeat 0 0;
}

.basic-fill {
  background: v.$primary;
}
