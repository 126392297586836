.ui-section{
    display: flex;
    border: 1px solid black;
}

.flex-row{
    flex-direction: row;
}

.flex-column{
    flex-direction: column;
}

