@use "../variables" as v;

h2 {
  // font-size: 50px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.song {
  font-size: 2.5vh;
  // border-bottom: 1px solid black;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .song {
//   font-size: 2vh;
//   // border-bottom: 1px solid black;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
